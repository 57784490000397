body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

header {
  background-color: #6200ea;
  color: white;
  padding: 10px;
  text-align: center;
}

header h1 {
  margin: 0;
}

nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

div {
  padding: 20px;
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* new  */

.home-container {
  padding: 20px;
  text-align: center;
}

.home-options-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.home-option-card {
  width: 200px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.home-option-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

h3 {
  font-size: 18px;
  margin: 10px 0;
}

p {
  font-size: 14px;
  color: #555;
}

.donate-now-button {
  margin-left: 15px; /* Add some space before the button */
  padding: 10px 20px;
  background-color: #27ae60; /* Green color for the button */
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.donate-now-button:hover {
  background-color: #219653; /* Darker green on hover */
}
